<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card class="pa-5">
            Shipping Address
            <v-text-field v-model="shippingAddress.name" name="name" label="Full Name" id="id"></v-text-field>
            <v-text-field v-model="shippingAddress.mobile" name="mobile" label="Mobile" id="id"></v-text-field>
            <v-text-field v-model="shippingAddress.city" name="place" label="City" id="id"></v-text-field>
            <v-text-field v-model="shippingAddress.pincode" name="pincode" label="PIN Code" id="id"></v-text-field>
            <v-textarea label="Address" v-model="shippingAddress.address"></v-textarea>
            <v-btn @click="placeOrder()" block color="success">Place Order</v-btn>
          </v-card>
        </v-col>
        <Snackbar :snackbar="snackbar" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      shippingAddress: {},
      snackbar: {
        isActive: false, //Activator
        x: "", // left or right or ""
        y: "", // top or bottom or ""
        color: "", // Any Color or ""
        mode: null, //vertical or multi-line or null
        // timeout: null, // set timeout Ex: 60000
        text: "" //Error Message
      }
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    ...mapActions(["add"]),
    placeOrder() {
      this.add({
        collection: "orders",
        data: {
          orderId: Date.now(),
          status: "new",
          createdAt: new Date(),
          products: this.$store.getters.cartProducts,
          user: this.shippingAddress,
          userId: this.currentUser.id
        }
      })
        .then(result => {
          this.axios.post(
            "https://api.telegram.org/bot" +
              process.env.VUE_APP_BOT_TOKEN +
              "/sendMessage",
            {
              chat_id: process.env.VUE_APP_CHAT_ID,
              text:
                "We have received a new order from " + this.currentUser.name,
              parse_mode: "Markdown"
            }
          );
          this.snackbar.text = "Successfully placed your order";
          this.snackbar.color = "success";
          this.snackbar.isActive = true;
          setTimeout(() => this.$router.replace("/"), 1500);
        })
        .catch(err => {
          this.snackbar.text = err.message;
          this.snackbar.color = "error";
          this.snackbar.isActive = true;
        });
    }
  }
};
</script>
